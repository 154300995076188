import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import './Style.css';

const Test = () => {
  const [showTest, setShowTest] = useState(true);
  const [score, setScore] = useState(0);
  const calculateScore = () => {
    let count = 0;
    for (const item of data) {
      if (item) {
        count += 1;
      }
    }
    setScore(count);
    setShowTest(false);
  };

  const data = [false, false, false, false, false, false, false, false, false, false,
                false, false, false, false, false, false, false, false, false, false,
                false, false, false, false, false, false, false, false, false, false,
                false, false, false, false, false, false, false, false, false, false,
                false, false, false, false, false, false, false, false, false, false,
                false, false, false, false, false, false, false, false, false, false,
                false, false, false, false, false, false, false, false, false, false,
                false, false, false, false, false, false, false, false, false, false,
                false, false, false, false, false, false, false, false, false, false,
                false, false, false, false, false, false, false, false, false, false]

  const changeData = (number) => {
    data[number] = !data[number];
  }

  const fetchResponse = () => {
    if (score >= 81) {
      return "idk what you're doing on the weekends, but all the more power to you. Lucifer would be proud.";
    } else if (score >= 66) {
      return "you like to have a good time, but know when to reign yourself in. good for you. just don't make too many bad choices.";
    } else if (score >= 56) {
      return "you're probably either in your 20s or a really rebellious teenager. whichever way, you seem like a good time. come to our rager this weekend?";
    } else if (score >= 46) {
      return "you're the most fun, but my mom would for sure let me hang out with you still. you've managed to find that sweet spot we all look for — teach us your ways.";
    } else if (score >= 34 ) {
      return "you are the happy medium - you checked both yes and no on this list, but you definitely know how to have fun. we can't wait to see where you'll be in a few years ;)";
    } else if (score >= 26) {
      return "you're getting there, but you're definitely just beginning to experiment with your wild half. welcome to the dark side, love, it's a fun place to be.";
    } else if (score >= 16) {
      return "you're the mom friend, which is great because everyone needs one. speaking of, can you come over this weekend? in all seriousness, though, don't be afraid to have your own fun sometimes, too.";
    } else if (score >= 11) {
      return "you know when to bend the rules, but I'd be shocked if you'd ever actually broken any. good for you, your record is probably cleaner than ours.";
    } else if (score >= 6) {
      return "you definitely experiment, but your heart is overall Pure(TM). your mom is SO proud of you right now!";
    } else {
      return "you're the friend that my parents would always let me hang out with and never ask any questions about it. fix your halo, honey, and keep up the good work.";
    }
  } 

  const fetchGroup = () => {
    if (score >= 81) {
      return "Devil Child";
    } else if (score >= 66) {
      return "Heathen";
    } else if (score >= 56) {
      return "Corrupted";
    } else if (score >= 46) {
      return "Baddie";
    } else if (score >= 34 ) {
      return "Wild Child";
    } else if (score >= 26) {
      return "Rebel";
    } else if (score >= 16) {
      return "Sweetheart";
    } else if (score >= 11) {
      return "Dream Kid";
    } else if (score >= 6) {
      return "Innocent";
    } else {
      return "Angel";
    }
  } 

  return (
   <div>
     {showTest ? 
      <div>
      <div class="test-description">
          fun fact (actually really, really sad fact), the Rice Purity Test was made in the 80s. that means that it includes none of the quintessential 21st century 
          rebellious activities (think sexting, fake IDs and tinder). welcome to two best friends' re-imagining of the classic purity test, we hope you have as much 
          fun as we did. NOTE: The Innocence Test is not a bucket list, nor do we endorse the behaviors listed below. 
      </div>
      <Form>
        <div className="test-container">
          <div className="test-box">
            <div class="item-row">
              1. <Form.Check onChange={() => changeData(0)} label="been on a date 💕" />
            </div>
            <div class="item-row">
              2. <Form.Check onChange={() => changeData(1)} label="been in a relationship 🥰" />
            </div>
            <div class="item-row">
              3. <Form.Check onChange={() => changeData(2)} label="kissed a mps (member of the preferred sex) 😘" />
            </div>
            <div class="item-row">
              4. <Form.Check onChange={() => changeData(3)} label="made out in public 😐" />
            </div>
            <div class="item-row">
              5. <Form.Check onChange={() => changeData(4)} label="played a game involved stripping 🩲" />
            </div>
            <div class="item-row">
              6. <Form.Check onChange={() => changeData(5)} label="masturbated 😈" />
            </div>
            <div class="item-row">
              7. <Form.Check onChange={() => changeData(6)} label="sent or received underwear pics 👙" />
            </div>
            <div class="item-row">
              8. <Form.Check onChange={() => changeData(7)} label="sent nudes 🚫" />
            </div>
            <div class="item-row">
              9. <Form.Check onChange={() => changeData(8)} label="received nudes ❌" />
            </div>
            <div class="item-row">
              10. <Form.Check onChange={() => changeData(9)} label="sexted 📲" />
            </div>
            <div class="item-row">
              11. <Form.Check onChange={() => changeData(10)} label="facetime sex 🤳" />
            </div>
            <div class="item-row">
              12. <Form.Check onChange={() => changeData(11)} label="kissed 3 or more people in one night. 👨‍👩‍👦" />
            </div>
            <div class="item-row">
              13. <Form.Check onChange={() => changeData(12)} label="FwB 👩🏼‍🤝‍👨🏽" />
            </div>
            <div class="item-row">
              14. <Form.Check onChange={() => changeData(13)} label="been in love 💖" />
            </div>
            <div class="item-row">
              15. <Form.Check onChange={() => changeData(14)} label="had a dating app 🔥" />
            </div>
            <div class="item-row">
              16. <Form.Check onChange={() => changeData(15)} label="paid for a premium version of a dating app 💸" />
            </div>
            <div class="item-row">
              17. <Form.Check onChange={() => changeData(16)} label="long distance relationship 🛫" />
            </div>
            <div class="item-row">
              18. <Form.Check onChange={() => changeData(17)} label="asked for a stranger’s contact info #️⃣" />
            </div>
            <div class="item-row">
              19. <Form.Check onChange={() => changeData(18)} label="been asked for your contact info by a stranger 👻" />
            </div>
            <div class="item-row">
              20. <Form.Check onChange={() => changeData(19)} label="gotten back with/hooked up with an ex ❎" />
            </div>
            <div class="item-row">
              21. <Form.Check onChange={() => changeData(20)} label="slid into someone’s DMs 📳" />
            </div>
            <div class="item-row">
              22. <Form.Check onChange={() => changeData(21)} label="watched porn 🟧⬛️" />
            </div>
            <div class="item-row">
              23. <Form.Check onChange={() => changeData(22)} label="paid for any porn 🤑" />
            </div>
            <div class="item-row">
              24. <Form.Check onChange={() => changeData(23)} label="watched porn with mps 🍿" />
            </div>
            <div class="item-row">
              25. <Form.Check onChange={() => changeData(24)} label="talked to 5 or more people at once 5️⃣" />
            </div>
            <div class="item-row">
              26. <Form.Check onChange={() => changeData(25)} label="posted a thirst trap 😜" />
            </div>
            <div class="item-row">
              27. <Form.Check onChange={() => changeData(26)} label="said “i love you” 😍" />
            </div>
            <div class="item-row">
              28. <Form.Check onChange={() => changeData(27)} label="been to a party 🎉" />
            </div>
            <div class="item-row">
              29. <Form.Check onChange={() => changeData(28)} label="thrown a party 🥳" />
            </div>
            <div class="item-row">
              30. <Form.Check onChange={() => changeData(29)} label="drank 🥂" />
            </div>
            <div class="item-row">
              31. <Form.Check onChange={() => changeData(30)} label="been drunk 😵" />
            </div>
            <div class="item-row">
              32. <Form.Check onChange={() => changeData(31)} label="blacked or yakked 🥴" />
            </div>
            <div class="item-row">
              33. <Form.Check onChange={() => changeData(32)} label="played a drinking game 🥤" />
            </div>
            <div class="item-row">
              34. <Form.Check onChange={() => changeData(33)} label="faked sobriety 🤥" />
            </div>
            <div class="item-row">
              35. <Form.Check onChange={() => changeData(34)} label="flirted with a stranger to get a free drink 🍻" />
            </div>
            <div class="item-row">
              36. <Form.Check onChange={() => changeData(35)} label="taken alcohol from your parents 🥃" />
            </div>
            <div class="item-row">
              37. <Form.Check onChange={() => changeData(36)} label="smoked weed 🍃" />
            </div>
            <div class="item-row">
              38. <Form.Check onChange={() => changeData(37)} label="done edibles 🍪" />
            </div>
            <div class="item-row">
              39. <Form.Check onChange={() => changeData(38)} label="hit a bong ⚗️" />
            </div>
            <div class="item-row">
              40. <Form.Check onChange={() => changeData(39)} label="nicotine (vaped) 💨" />
            </div>
            <div class="item-row">
              41. <Form.Check onChange={() => changeData(40)} label="smoked a cigarette 🚬" />
            </div>
            <div class="item-row">
              42. <Form.Check onChange={() => changeData(41)} label="cocaine or a drug stronger 💉" />
            </div>
            <div class="item-row">
              43. <Form.Check onChange={() => changeData(42)} label="had a fake 🆔" />
            </div>
            <div class="item-row">
              44. <Form.Check onChange={() => changeData(43)} label="had a fake ID confiscated 👮‍♂️" />
            </div>
            <div class="item-row">
              45. <Form.Check onChange={() => changeData(44)} label="used a fake to buy alcohol/drugs 💰" />
            </div>
            <div class="item-row">
              46. <Form.Check onChange={() => changeData(45)} label="used a fake to get into a bar/club 🕺" />
            </div>
            <div class="item-row">
              47. <Form.Check onChange={() => changeData(46)} label="had the police called on you 🚔" />
            </div>
            <div class="item-row">
              48. <Form.Check onChange={() => changeData(47)} label="gotten in trouble with school or law 🙁" />
            </div>
            <div class="item-row">
              49. <Form.Check onChange={() => changeData(48)} label="showered with mps 🚿" />
            </div>
            <div class="item-row">
              50. <Form.Check onChange={() => changeData(49)} label="given/received a hickey 💋" />
            </div>
          </div>
          <div className="test-box">
            <div class="item-row">
              51. <Form.Check onChange={() => changeData(50)} label="had/given blue balls 🔵" />
            </div>
            <div class="item-row">
              52. <Form.Check onChange={() => changeData(51)} label="someone else made you orgasm 🤯" />
            </div>
            <div class="item-row">
              53. <Form.Check onChange={() => changeData(52)} label="cheated/been cheated on/been cheated with 🤮" />
            </div>
            <div class="item-row">
              54. <Form.Check onChange={() => changeData(53)} label="bought plan b 👼🏼" />
            </div>
            <div class="item-row">
              55. <Form.Check onChange={() => changeData(54)} label="bought birth control 💊" />
            </div>
            <div class="item-row">
              56. <Form.Check onChange={() => changeData(55)} label="given head 🧠" />
            </div>
            <div class="item-row">
              57. <Form.Check onChange={() => changeData(56)} label="received head 👄" />
            </div>
            <div class="item-row">
              58. <Form.Check onChange={() => changeData(57)} label="used a sex toy 🧸" />
            </div>
            <div class="item-row">
              59. <Form.Check onChange={() => changeData(58)} label="spent the night with mps 💤" />
            </div>
            <div class="item-row">
              60. <Form.Check onChange={() => changeData(59)} label="been walked in on while engaging in a sexual act 😳" />
            </div>
            <div class="item-row">
              61. <Form.Check onChange={() => changeData(60)} label="sexiled a roommate 👋🏼" />
            </div>
            <div class="item-row">
              62. <Form.Check onChange={() => changeData(61)} label="had sex 🍆" />
            </div>
            <div class="item-row">
              63. <Form.Check onChange={() => changeData(62)} label="had sex 3 or more times in one night. 🏘" />
            </div>
            <div class="item-row">
              64. <Form.Check onChange={() => changeData(63)} label="STI/STD test 🦠" />
            </div>
            <div class="item-row">
              65. <Form.Check onChange={() => changeData(64)} label="had car sex 🚘" />
            </div>
            <div class="item-row">
              66. <Form.Check onChange={() => changeData(65)} label="had sex in public 📢" />
            </div>
            <div class="item-row">
              67. <Form.Check onChange={() => changeData(66)} label="used blindfold/handcuffs in bed 🤭" />
            </div>
            <div class="item-row">
              68. <Form.Check onChange={() => changeData(67)} label="joined the mile high club 🛫" />
            </div>
            <div class="item-row">
              69. <Form.Check onChange={() => changeData(68)} label="❓" />
            </div>
            <div class="item-row">
              70. <Form.Check onChange={() => changeData(69)} label="3some 3️⃣" />
            </div>
            <div class="item-row">
              71. <Form.Check onChange={() => changeData(70)} label="orgy! 🔢" />
            </div>
            <div class="item-row">
              72. <Form.Check onChange={() => changeData(71)} label="body count > 5 📈" />
            </div>
            <div class="item-row">
              73. <Form.Check onChange={() => changeData(72)} label="sex tape. 🎥" />
            </div>
            <div class="item-row">
              74. <Form.Check onChange={() => changeData(73)} label="anal 🍑" />
            </div>
            <div class="item-row">
              75. <Form.Check onChange={() => changeData(74)} label="period sex 🩸" />
            </div>
            <div class="item-row">
              76. <Form.Check onChange={() => changeData(75)} label="bought a pregnancy test 📄" />
            </div>
            <div class="item-row">
              77. <Form.Check onChange={() => changeData(76)} label="been preggo/ gotten someone preggo 🤰" />
            </div>
            <div class="item-row">
              78. <Form.Check onChange={() => changeData(77)} label="streaked 👀" />
            </div>
            <div class="item-row">
              79. <Form.Check onChange={() => changeData(78)} label="skinny dipped 🌊" />
            </div>
            <div class="item-row">
              80. <Form.Check onChange={() => changeData(79)} label="seen a stripper 👅" />
            </div>
            <div class="item-row">
              81. <Form.Check onChange={() => changeData(80)} label="run from the police 👮" />
            </div>
            <div class="item-row">
              82. <Form.Check onChange={() => changeData(81)} label="been questioned by/handcuffed by police 🚨" />
            </div>
            <div class="item-row">
              83. <Form.Check onChange={() => changeData(82)} label="committed vandalism 🎨" />
            </div>
            <div class="item-row">
              84. <Form.Check onChange={() => changeData(83)} label="driven under the influence 🚙" />
            </div>
            <div class="item-row">
              85. <Form.Check onChange={() => changeData(84)} label="texted while driving 💬" />
            </div>
            <div class="item-row">
              86. <Form.Check onChange={() => changeData(85)} label="gotten a tattoo 🖋️" />
            </div>
            <div class="item-row">
              87. <Form.Check onChange={() => changeData(86)} label="pierced something other than your ears 👃" />
            </div>
            <div class="item-row">
              88. <Form.Check onChange={() => changeData(87)} label="stolen something 💲" />
            </div>
            <div class="item-row">
              89. <Form.Check onChange={() => changeData(88)} label="crashed a car 🏎" />
            </div>
            <div class="item-row">
              90. <Form.Check onChange={() => changeData(89)} label="blocked someone on social media 👎🏽" />
            </div>
            <div class="item-row">
              91. <Form.Check onChange={() => changeData(90)} label="gotten kicked out of a party 😡" />
            </div>
            <div class="item-row">
              92. <Form.Check onChange={() => changeData(91)} label="been at a party that got raided 🚒" />
            </div>
            <div class="item-row">
              93. <Form.Check onChange={() => changeData(92)} label="snuck out 😰" />
            </div>
            <div class="item-row">
              94. <Form.Check onChange={() => changeData(93)} label="had corona! 😷" />
            </div>
            <div class="item-row">
              95. <Form.Check onChange={() => changeData(94)} label="had a crush on a teacher 👩‍🏫" />
            </div>
            <div class="item-row">
              96. <Form.Check onChange={() => changeData(95)} label="stalked someone’s snapchat location 📍" />
            </div>
            <div class="item-row">
              97. <Form.Check onChange={() => changeData(96)} label="gotten with 2+ people in the same friend group 👏🏼" />
            </div>
            <div class="item-row">
              98. <Form.Check onChange={() => changeData(97)} label="whole snap best friends list has been love interests 😎" />
            </div>
            <div class="item-row">
              99. <Form.Check onChange={() => changeData(98)} label="gotten with someone 3+ years older than you 👵" />
            </div>
            <div class="item-row">
              100. <Form.Check onChange={() => changeData(99)} label="gotten with someone 3+ years younger than you 👶" />
            </div>
          </div>
        </div>
      </Form>
      <div>
        <Button onClick={() => calculateScore()}>Calculate My Score!</Button>
      </div>
    </div> 
    : 
    <div class="result-container"> 
      <Button onClick={() => setShowTest(true)}>Back</Button>
      <div className="score-style">        
      {fetchGroup()} ({score})
      </div> 
      <div class="response-style">
        {fetchResponse()}
      </div>
    </div> 
    }
   </div>
  )
}

export default Test;