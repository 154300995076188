import React, { Component } from "react";
import Test from "./Test"
import "./Style.css";
import AdSense from 'react-adsense';

class LambdaDemo extends Component {
  constructor(props) {
    super(props)
    this.state = { loading: false, msg: null }
  }

  handleClick = api => e => {
    e.preventDefault()

    this.setState({ loading: true })
    fetch("/.netlify/functions/" + api)
      .then(response => response.json())
      .then(json => this.setState({ loading: false, msg: json.msg }))
  }

  render() {
    const { msg } = this.state

    return (
      <p>
        <br />
        <span>{msg}</span>
      </p>
    )
  }
}

class App extends Component {
  render() {
    return (
      <div className="App">
        <p className="App-header">
          The Innocence Test
        </p>
        <Test />
        <div clasName="margin-wrapper">
          <AdSense.Google
          client='ca-pub-7292810486004926'
          slot='7806394673'
          style={{ display: 'block' }}
          layout='in-article'
          format='fluid'
          />
        </div>
        
        <header className="App-header">            
          <LambdaDemo />
        </header>
      </div>
    )
  }
}

export default App
